function initVideoPager() {
  document.querySelectorAll('[data-hero-video-pager]').forEach((videoPager) => {
    const uuid = videoPager.dataset.uuid;
    const videoModal = document.getElementById(`video-modal-${uuid}`);
    const videoModalHeader = videoModal.querySelector('.modal-header');
    const videoContainer = videoModal.querySelector('[data-video-container]');
    const videos = videoContainer.querySelectorAll('[data-video-index]');
    const thumbnailArea = videoPager.querySelector('[data-thumbnail-navigation]');
    const thumbnailScrollableArea = videoPager.querySelector('[data-thumbnail-scrollable-area]');
    const thumbnails = thumbnailArea.querySelectorAll('[data-thumbnail-index]');
    const previousButton = videoPager.querySelector('[data-navigation-btn="-1"]');
    const nextButton = videoPager.querySelector('[data-navigation-btn="1"]');

    const videoCount = videos.length;
    let activeIndex = 0;

    function handleArrowClick(e) {
      const { currentTarget } = e;
      const direction = Number(currentTarget.dataset.navigationBtn);
      let currentIndex = Number(videoPager.querySelector('[data-active-thumbnail-image]').dataset.thumbnailIndex);
      currentIndex += direction;
      currentIndex = Math.min(Math.max(0, currentIndex), videoCount - 1);
      scrollToIndex(currentIndex);
    }

    function handleThumbnailClick(e) {
      const { currentTarget } = e;
      const imgIndexNumber = Number(currentTarget.dataset.thumbnailIndex);
      scrollToIndex(imgIndexNumber);
    }

    function scrollToIndex(index) {
      activeIndex = index;

      // set active thumbnail in scrollable area
      thumbnails.forEach((thumbnail) => {
        const thumbnailIndex = Number(thumbnail.dataset.thumbnailIndex);

        if (thumbnailIndex === index) {
          thumbnailScrollableArea.scrollTo({ top: thumbnail.offsetTop, left: thumbnail.offsetLeft, behavior: 'smooth' });
          thumbnail.classList.add('active');
          thumbnail.setAttribute('data-active-thumbnail-image', '');
        } else {
          thumbnail.classList.remove('active');
          thumbnail.removeAttribute('data-active-thumbnail-image');
        }
      });

      setButtonState();
    }

    function setButtonState() {
      const thumbnailItems = thumbnailScrollableArea.querySelectorAll('[data-thumbnail-index]');

      // disable prev/next buttons based on index
      if (activeIndex === 0) {
        previousButton.classList.add('disabled');
        nextButton.classList.remove('disabled');
      } else if (activeIndex === thumbnailItems.length - 1) {
        previousButton.classList.remove('disabled');
        nextButton.classList.add('disabled');
      } else {
        nextButton.classList.remove('disabled');
        previousButton.classList.remove('disabled');
      }
    }

    function sortThumbnails() {
      const thumbnailArray = Array.from(thumbnails);

      thumbnailArray.sort((a, b) => {
        const openedA = a.getAttribute('data-opened');
        const openedB = b.getAttribute('data-opened');
        return openedA - openedB;
      });

      const parentNode = thumbnails[0].parentNode;
      thumbnailArray.forEach((el, i) => {
        parentNode.appendChild(el);
        el.dataset.thumbnailIndex = i.toString();
      });
    }

    function dragElement(el) {
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (videoModalHeader) {
        videoModalHeader.onmousedown = dragMouseDown;
      } else {
        el.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get cursor position:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set element's new position:
        el.style.top = (el.offsetTop - pos2) + 'px';
        el.style.left = (el.offsetLeft - pos1) + 'px';
      }

      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

    previousButton.addEventListener('click', handleArrowClick);
    nextButton.addEventListener('click', handleArrowClick);

    thumbnails.forEach((thumbnail) => {
      thumbnail.addEventListener('click', handleThumbnailClick);

      // disable thumbnail video play buttons for screen readers
      const button = thumbnail.querySelector('button');
      button.setAttribute('aria-hidden', 'true');
      button.setAttribute('aria-disabled', 'true');
      button.setAttribute('tabindex', '-1');
      button.setAttribute('disabled', '');
      button.setAttribute('role', 'presentation');
    });

    videoModal.addEventListener('shown.bs.modal',  function () {
      const activeThumbnail = thumbnailScrollableArea.querySelector('[data-active-thumbnail-image]');
      const activeVideoIndex = activeThumbnail.dataset.videoIndex;
      const activeVideo = this.querySelector(`[data-video-index="${activeVideoIndex}"]`);

      if (activeVideo) {
        activeThumbnail.setAttribute('data-opened', Date.now().toString());
        activeVideo.setAttribute('data-active-video', '');
        activeVideo.classList.add('d-block');
        activeVideo.classList.remove('d-none');

        const activeVideoPlayer = activeVideo.querySelector('video');

        if (activeVideoPlayer) {
          activeVideoPlayer.play();
        }
      }
    });

    videoModal.addEventListener('hidden.bs.modal',  function () {
      const activeVideo = this.querySelector('[data-active-video]');

      if (activeVideo) {
        const activeVideoPlayer = activeVideo.querySelector('video');
        activeVideo.removeAttribute('data-active-video');
        activeVideo.classList.add('d-none');
        activeVideo.classList.remove('d-block');

        if (activeVideoPlayer) {
          activeVideoPlayer.pause();
          activeVideoPlayer.currentTime = 0;
        }
      }

      // re-sort thumbnails, clear/set active item state
      sortThumbnails();
      thumbnails.forEach(thumbnail => thumbnail.classList.remove('active'));
      const firstItem = thumbnailScrollableArea.querySelectorAll('[data-thumbnail-index]')[0];
      firstItem.classList.add('active');
      firstItem.setAttribute('data-active-thumbnail-image', '');
      activeIndex = Number(firstItem.dataset.thumbnailIndex);

      setTimeout(() => {
        thumbnailScrollableArea.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, 250);

      setButtonState();
    });

    dragElement(videoModal);
    scrollToIndex(activeIndex);

  });
}

document.addEventListener('DOMContentLoaded', initVideoPager);
